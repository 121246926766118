import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TextCustomFieldConfig, SharedModule, FormInputComponent, DataService } from '@vendure/admin-ui/core';

@Component({
    selector: 'vdr-store-operation-form',
    templateUrl: './store-operation-form.component.html',
    styleUrls: ['./store-operation-form.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class StoreOperationFormComponent implements FormInputComponent<TextCustomFieldConfig> {
    readonly: boolean;
    config: TextCustomFieldConfig;
    formControl: FormControl;

    defaultValues = {
        day: '',
        openingTime: '10:00',
        closingTime: '20:00',
    };

    constructor(
        private dataService: DataService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        if (this.formControl.value) {
            this.defaultValues = JSON.parse(this.formControl.value);
        } else {
            this.formControl.setValue(JSON.stringify(this.defaultValues));
        }
    }

    onValueChange(event: Event, type: 'day' | 'openingTime' | 'closingTime') {
        const newValue = (event.target as HTMLInputElement).value;
        this.defaultValues[type] = newValue;
        this.formControl.setValue(JSON.stringify(this.defaultValues));
    }
}
