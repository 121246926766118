import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule, addNavMenuSection, addNavMenuItem } from '@vendure/admin-ui/core';

@NgModule({
    imports: [SharedModule, NgSelectModule],
    providers: [
        addNavMenuItem(
            {
                id: 'template',
                label: 'Marketing',
                routerLink: ['/extensions/template'],
                icon: 'email',
                requiresPermission: 'SuperAdmin',
            },
            'marketing',
        ),
    ],
})
export class TemplateExtensionModule {}
