import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateExtensionModule } from './extensions/3e5149813cccba97acb6a507294457565a793f2bd005de0eab2bdadead88282e/extensions.module';
import { ReviewsUiExtensionModule } from './extensions/b6c6177a4ab5f36f11df888e1afd565127adda311e1f00991a43f6bb7ff7d44b/reviews-ui-extension.module';
import { PagesSharedModule } from './extensions/pages/pages-ui-shared.module';
import { OrderExtensionModule } from './extensions/24d3898a88dcc7d2a69451110363193aaf527f86cb47a9b0df8ed2ac2e9e322a/shared.module';

import SharedProviders_0_0 from './extensions/d7af5581f5e5b27d1af030cca921af83ef1a475d15b9018ced00ae999ccbb933/providers';
import SharedProviders_1_0 from './extensions/modify-nav/providers';
import SharedProviders_2_0 from './extensions/color-picker-component/providers';
import SharedProviders_3_0 from './extensions/date-time-forms/providers';
import SharedProviders_7_0 from './extensions/feedback/providers';
import SharedProviders_9_0 from './extensions/analytics/providers';


@NgModule({
    imports: [CommonModule, TemplateExtensionModule, ReviewsUiExtensionModule, PagesSharedModule, OrderExtensionModule],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0, ...SharedProviders_3_0, ...SharedProviders_7_0, ...SharedProviders_9_0],
})
export class SharedExtensionsModule {}
