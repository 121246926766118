import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule, addNavMenuSection, addNavMenuItem } from '@vendure/admin-ui/core';

@NgModule({
    imports: [SharedModule],
    providers: [
        addNavMenuItem(
            {
                id: 'tax-categories',
                label: 'Tax Categories and Rates',
                requiresPermission: 'SuperAdmin',
                icon: 'edit',
                routerLink: ['/extensions/sales/order'],
            },
            'settings',
        ),
    ],
})
export class OrderExtensionModule {}
