<ng-template vdrDialogTitle>
    <ng-container *ngIf="isProductVariantMode; else productModeTitle">
        {{ 'catalog.assign-variants-to-channel' | translate }}
    </ng-container>
    <ng-template #productModeTitle>{{ 'catalog.assign-products-to-channel' | translate }}</ng-template>
</ng-template>

<div class="flex">
    <ng-select
        [items]="availableChannels"
        bindLabel="code"
        bindValue="id"
        [multiple]="true"
        [clearable]="false"
        [(ngModel)]="selectedChannelIds"
        (change)="selectChannel(selectedChannelIds)"
        placeholder="Select channels"
    >
        <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ item.code }}
        </ng-template>
    </ng-select>

    <div class="flex-spacer"></div>
    <clr-input-container>
        <label>{{ 'catalog.price-conversion-factor' | translate }}</label>
        <input clrInput type="number" min="0" max="99999" [formControl]="priceFactorControl" />
    </clr-input-container>
</div>

<div class="channel-price-preview">
    <label class="clr-control-label">{{ 'catalog.channel-price-preview' | translate }}</label>
    <table class="table">
        <thead>
            <tr>
                <th>{{ 'common.name' | translate }}</th>
                <th>
                    {{
                        'catalog.price-in-channel'
                            | translate: { channel: currentChannel?.code | channelCodeToLabel | translate }
                    }}
                </th>
                <th>
                    <ng-template [ngIf]="selectedChannels.length" [ngIfElse]="noSelection">
                        {{ 'catalog.price-in-channel' | translate: { channel: selectedChannel?.code } }}
                    </ng-template>
                    <ng-template #noSelection>
                        {{ 'catalog.no-channel-selected' | translate }}
                    </ng-template>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of variantsPreview$ | async">
                <td>{{ row.name }}</td>
                <td>{{ row.price | localeCurrency: currentChannel?.defaultCurrencyCode }}</td>
                <td>
                    <ng-template [ngIf]="selectedChannel" [ngIfElse]="noChannelSelected">
                        {{ row.pricePreview | localeCurrency: selectedChannel?.defaultCurrencyCode }}
                    </ng-template>
                    <ng-template #noChannelSelected>-</ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template vdrDialogButtons>
    <button type="button" class="btn" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
    <button type="submit" (click)="assign()" class="btn btn-primary">
        <ng-template [ngIf]="selectedChannels.length > 0" [ngIfElse]="noSelection">Assign</ng-template>
        <ng-template #noSelection>
            {{ 'catalog.no-channel-selected' | translate }}
        </ng-template>
    </button>
</ng-template>
