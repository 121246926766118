import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StringCustomFieldConfig, SharedModule, FormInputComponent } from '@vendure/admin-ui/core';

@Component({
    template: `
        <input class="date-only" type="date" [formControl]="formControl" />
    `,
    styleUrls: ['./date-only-form.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class DateOnlyFormComponent implements FormInputComponent<StringCustomFieldConfig> {
    readonly: boolean;
    config: StringCustomFieldConfig;
    formControl: FormControl;
}
