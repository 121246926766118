import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StringCustomFieldConfig, SharedModule, FormInputComponent } from '@vendure/admin-ui/core';

@Component({
    template: `
        <input class="time-only" type="time" [formControl]="formControl" />
    `,
    styleUrls: ['./time-only-form.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class TimeOnlyFormComponent implements FormInputComponent<StringCustomFieldConfig> {
    readonly: boolean;
    config: StringCustomFieldConfig;
    formControl: FormControl;
}
