import { registerFormInputComponent } from '@vendure/admin-ui/core';
import { DateOnlyFormComponent } from './components/date-only-form/date-only-form.component';
import { TimeOnlyFormComponent } from './components/time-only-form/time-only-form.component';
import { StoreOperationFormComponent } from './components/store-operation-form/store-operation-form.component';

export default [
    registerFormInputComponent('date-only-form-input', DateOnlyFormComponent),
    registerFormInputComponent('time-only-form-input', TimeOnlyFormComponent),
    registerFormInputComponent('store-operation-form-input', StoreOperationFormComponent),
];
