import { NgModule } from '@angular/core';
import { SharedModule, addNavMenuSection } from '@vendure/admin-ui/core';

@NgModule({
    imports: [SharedModule],
    providers: [
        addNavMenuSection(
            {
                id: 'pages-nav',
                label: 'Static Pages',
                items: [
                    {
                        id: 'pages',
                        label: 'Pages',
                        routerLink: ['/extensions/page/pages'],
                        icon: 'tags',
                    },
                ],
            },
            // Add this section before the "settings" section
            'settings',
        ),
    ],
})
export class PagesSharedModule {}
