import React from 'react';
import { useFormControl, ReactFormInputOptions, useInjector } from '@vendure/admin-ui/react';

import { HexColorPicker } from 'react-colorful';

export function ColorPickerComponent({ readonly, config }: ReactFormInputOptions) {
    const { value, setFormValue } = useFormControl();

    return (
        <>
            <HexColorPicker
                color={value}
                onChange={color => {
                    setFormValue(color);
                }}
            />
            {value}
        </>
    );
}
