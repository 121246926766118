export const extensionRoutes = [  {
    path: 'catalog/products',
    loadChildren: () => import('./extensions/d7af5581f5e5b27d1af030cca921af83ef1a475d15b9018ced00ae999ccbb933/routes'),
  },
  {
    path: 'extensions/template',
    loadChildren: () => import('./extensions/3e5149813cccba97acb6a507294457565a793f2bd005de0eab2bdadead88282e/lazy.module').then(m => m.TemplateModule),
  },
  {
    path: 'extensions/product-reviews',
    loadChildren: () => import('./extensions/b6c6177a4ab5f36f11df888e1afd565127adda311e1f00991a43f6bb7ff7d44b/reviews-ui-lazy.module').then(m => m.ReviewsUiLazyModule),
  },
  {
    path: 'extensions/page',
    loadChildren: () => import('./extensions/pages/pages-ui-lazy.module').then(m => m.PagesLazyModule),
  },
  {
    path: 'extensions/sales',
    loadChildren: () => import('./extensions/feedback/route'),
  },
  {
    path: 'extensions/sales',
    loadChildren: () => import('./extensions/24d3898a88dcc7d2a69451110363193aaf527f86cb47a9b0df8ed2ac2e9e322a/lazy.module').then(m => m.OrderUIModule),
  },
  {
    path: 'extensions/analytics',
    loadChildren: () => import('./extensions/analytics/route'),
  }];
