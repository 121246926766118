import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
    DataService,
    Dialog,
    GetChannelsQuery,
    ItemOf,
    LogicalOperator,
    NotificationService,
} from '@vendure/admin-ui/core';
import { combineLatest, from, lastValueFrom, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SharedModule } from '@vendure/admin-ui/core';

type Channel = ItemOf<GetChannelsQuery, 'channels'>;

@Component({
    standalone: true,
    imports: [SharedModule], // Add NgSelectModule here
    selector: 'vdr-assign-products-to-channel-dialog',
    templateUrl: './assign-products-to-channel-dialog.component.html',
    styleUrls: [
        './assign-products-to-channel-dialog.component.scss',
        // 'node_modules/@ng-select/ng-select/themes/default.theme.css',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignProductsToChannel implements OnInit, Dialog<any> {
    selectedChannels: Channel[] = [];
    currentChannel: Channel;
    availableChannels: Channel[];
    resolveWith: (result?: any) => void;
    variantsPreview$: Observable<Array<{ id: string; name: string; price: number; pricePreview: number }>>;
    priceFactorControl = new UntypedFormControl(1);
    selectedChannelIds: string[] = []; // Add this property

    // assigned by ModalService.fromComponent() call
    productIds: string[];
    productVariantIds: string[] | undefined;
    currentChannelIds: string[];

    get isProductVariantMode(): boolean {
        return this.productVariantIds != null;
    }

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
    ) {}

    ngOnInit() {
        const activeChannelId$ = this.dataService.client
            .userStatus()
            .mapSingle(({ userStatus }) => userStatus.activeChannelId);
        const allChannels$ = this.dataService.settings.getChannels().mapSingle(data => data.channels);

        combineLatest([activeChannelId$, allChannels$]).subscribe(([activeChannelId, channels]) => {
            this.currentChannel = channels.items.find(c => c.id === activeChannelId)!;
            this.availableChannels = channels.items.filter(channel => channel.id !== this.currentChannel.id);

            this.currentChannelIds.forEach(id => {
                const channel = this.availableChannels.find(
                    c => c.id === id && c.id !== this.currentChannel.id,
                );
                if (channel) {
                    this.selectedChannels.push(channel);
                }
            });
            console.log(this.availableChannels, 'availableChannels');
            // Initialize selectedChannelIds based on selectedChannels
            this.selectedChannelIds = this.selectedChannels.map(channel => channel.id);
        });

        this.variantsPreview$ = combineLatest(
            from(this.getTopVariants(10)),
            this.priceFactorControl.valueChanges.pipe(startWith(1)),
        ).pipe(
            map(([variants, factor]) =>
                variants.map(v => ({
                    id: v.id,
                    name: v.name,
                    price: v.price,
                    pricePreview: v.price * +factor,
                })),
            ),
        );
    }

    selectChannel(channelIds: string[]) {
        console.log(channelIds, 'selectChannel');
        this.selectedChannels = channelIds
            .map(id => this.availableChannels.find(channel => channel.id === id))
            .filter(channel => !!channel && channel.code !== '___default_channel__') as Channel[];
    }

    assign() {
        // const allExceptDefaultChannel = this.availableChannels.filter(
        //     channel => channel.code !== '___default_channel__',
        // );
        //
        // console.log(allExceptDefaultChannel, 'allExceptDefaultChannel');
        // // first remove all except defaultChannel
        //
        // allExceptDefaultChannel.forEach(channel => {
        //     const test = this.dataService.product
        //         .removeProductsFromChannel({
        //             channelId: channel.id,
        //             productIds: this.productIds,
        //         })
        //         .subscribe(data => {
        //             console.log(data, 'data');
        //         });
        //     console.log(test, 'test');
        // });

        console.log(this.selectedChannels, 'selectedChannels');
        this.selectedChannels.forEach(selectedChannel => {
            if (selectedChannel) {
                if (!this.isProductVariantMode) {
                    this.dataService.product
                        .assignProductsToChannel({
                            channelId: selectedChannel.id,
                            productIds: this.productIds,
                            priceFactor: +this.priceFactorControl.value,
                        })
                        .subscribe(() => {
                            this.notificationService.success(_('catalog.assign-product-to-channel-success'), {
                                channel: selectedChannel.code,
                                count: this.productIds.length,
                            });
                            this.resolveWith(true);
                        });
                } else if (this.productVariantIds) {
                    this.dataService.product
                        .assignVariantsToChannel({
                            channelId: selectedChannel.id,
                            productVariantIds: this.productVariantIds,
                            priceFactor: +this.priceFactorControl.value,
                        })
                        .subscribe(() => {
                            this.notificationService.success(_('catalog.assign-variant-to-channel-success'), {
                                channel: selectedChannel.code,
                                count: this.productVariantIds!.length,
                            });
                            this.resolveWith(true);
                        });
                }
            }
        });
    }

    cancel() {
        this.resolveWith();
    }

    private async getTopVariants(take: number) {
        return (
            await lastValueFrom(
                this.dataService.product.getProductVariants({
                    filterOperator: LogicalOperator.OR,
                    filter: {
                        productId: { in: this.productIds },
                        id: { in: this.productVariantIds },
                    },
                    take,
                }).single$,
            )
        ).productVariants.items;
    }
}
