<div class="store-operation">
    <span class="time-details">
        <select value="{{ defaultValues.day }}" (change)="onValueChange($event, 'day')">
            <option value="" disabled selected>Select Day</option>
            <option value="sunday" selected>Sunday</option>
            <option value="monday">Monday</option>
            <option value="tuesday">Tuesday</option>
            <option value="wednesday">Wednesday</option>
            <option value="thursday">Thursday</option>
            <option value="friday">Friday</option>
            <option value="saturday">Saturday</option>
        </select>
    </span>
    <span class="time-details">
        <input
            type="time"
            value="{{ defaultValues.openingTime }}"
            (change)="onValueChange($event, 'openingTime')"
        />
    </span>

    <span class="time-details">
        <input
            type="time"
            value="{{ defaultValues.closingTime }}"
            (change)="onValueChange($event, 'closingTime')"
        />
    </span>
</div>
