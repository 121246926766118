import { addNavMenuSection } from '@vendure/admin-ui/core';

export default [
    addNavMenuSection(
        {
            id: 'analytics',
            label: 'Analytics',
            items: [
                {
                    id: 'order-analytics',
                    label: 'Order Analytics',
                    routerLink: ['/extensions/analytics/order'],
                    icon: 'filter-grid',
                },
                {
                    id: 'customer-analytics',
                    label: 'Customer Analytics',
                    routerLink: ['/extensions/analytics/customer'],
                    requiresPermission: 'ViewCustomerAnalytics',
                    icon: 'filter-grid',
                },
            ],
        },
        'customers',
    ),
];
